import React, { useState,useEffect } from "react";
import { useRef } from "react";
import { Canvas,useThree, useFrame } from "@react-three/fiber";
import { Suspense } from "react";
import "./style.css";
import SpaceShip from "./models/spaceship";
import Stars from "./models/stars";
import { Text, OrbitControls  } from "@react-three/drei";
import Font from "./Anta-Regular.ttf"
import gsap from "gsap";

const CameraControls = (props) => {
    const controls = useRef();
    const {camera,gl} = useThree();
    useEffect( () => {
        if (props.Iletisim === "iletişim") {
            async function İletisimfunction(){
                 gsap.to(controls.current.target, {
                    duration: 2,
                    x: 10, // X ekseninde hedefi değiştir
                    y: 5, // Y ekseninde hedefi değiştir
                    z: 15, // Z ekseninde hedefi değiştir
                    onUpdate: () => controls.current.update(),
                  });
                  await gsap.to(camera.position, {
                    duration: 2,
                    x: 0, // X ekseninde hedefi değiştir
                    y: 10, // Y ekseninde hedefi değiştir
                    z: 10, // Z ekseninde hedefi değiştir
                  });
                  props.Hook("default");
            }
            İletisimfunction();

        } else if(props.Iletisim === "Anasayfa"){
            async function Anasayfafunc(){
                  gsap.to(controls.current.target, {
                    duration: 2,
                    x: 0, // X ekseninde hedefi değiştir
                    y: 0, // Y ekseninde hedefi değiştir
                    z: 0, // Z ekseninde hedefi değiştir
                    onUpdate: () => controls.current.update(),
                  });
                  await gsap.to(camera.position, {
                    duration: 2,
                    x: 0, // X ekseninde hedefi değiştir
                    y: 0, // Y ekseninde hedefi değiştir
                    z: 10, // Z ekseninde hedefi değiştir
                  });
                  props.Hook("default");
            }
            Anasayfafunc();
        }
      }, [props.Iletisim]);
    return (
    <React.StrictMode>
    <OrbitControls target={[0, 0, 0]} ref={controls} enablePan={true} enableZoom={true} enableRotate={true} enableDamping dampingFactor={0.2} args={[camera,gl.domElement]}/>
    </React.StrictMode>
    );
  };
const Home = () => {
    const [Camera , SetCamera] = useState("default");
    const [TextObject,  SetTextObject] = useState({
        scale : 0.75,
        positions : {
            positionx: 5,
            TopPositionY: 2,
            BottomPositionY: 1
        }
    })
    const [TextObjectIletisim , SetTextObjectIletisim] = useState({
        IletisimScale : 0.75,
        positioniletisim : {
            iletisimpositionx : 10,
            TopIletisimPositionY : 8,
            BottomIletisimPositionY : 7,
            positionZ : 14
        }
    })
     function IletisimA(){
        SetCamera("iletişim");

    }
    function Anasayfa(){
        SetCamera("Anasayfa");
    }
    const calculateScale = () =>{
        const screenWidth = window.innerWidth;
        let scale = 0.75;
        let positionx = 5;
        let TopPositionY = 2;
        let BottomPositionY = 1;
        let IletisimPositionx = 10;
        let topIletisimPositionY = 8;
        let bottomIletisimPositionY = 7;
        let IletisimPositionZ = 14;
        let iletisimscale = 0.75;
        if (screenWidth < 768) {
            scale= 0.30
            positionx = -0.2
            TopPositionY = 4
            BottomPositionY = 3
            IletisimPositionx = 8
            topIletisimPositionY = 10
            bottomIletisimPositionY = 9
            iletisimscale = 0.25
        }
          else if (screenWidth < 1025) {
            scale = 0.40
            positionx = 2;
            iletisimscale = 0.40
          } else if(screenWidth < 1440) {
            scale = 0.50;
            positionx = 3
            IletisimPositionx = 11
            topIletisimPositionY = 9
            bottomIletisimPositionY = 8;
            IletisimPositionZ = 16;
            iletisimscale = 0.50;
        } 
        if( screenWidth < 321){
            scale = 0.20
            console.log("baya küçük")
            iletisimscale = 0.17
        }
        const newTextObject = { ...TextObject };
        newTextObject.scale = scale; 
        newTextObject.positions.positionx = positionx; 
        newTextObject.positions.TopPositionY = TopPositionY; 
        newTextObject.positions.BottomPositionY = BottomPositionY; 
        const IletisimTextObject = {...TextObjectIletisim};
        IletisimTextObject.positioniletisim.iletisimpositionx = IletisimPositionx;
        IletisimTextObject.positioniletisim.TopIletisimPositionY = topIletisimPositionY;
        IletisimTextObject.positioniletisim.BottomIletisimPositionY = bottomIletisimPositionY
        IletisimTextObject.positioniletisim.positionZ = IletisimPositionZ
        IletisimTextObject.IletisimScale = iletisimscale;
        SetTextObject(newTextObject);
        SetTextObjectIletisim(IletisimTextObject);
    }
    useEffect(() =>{
        calculateScale();
        window.addEventListener("resize", calculateScale);
        return () => {
          window.removeEventListener("resize", calculateScale);
        };
    },[])
  return (
    <div className="page">
        <div className="navbar">
            <a className="logo navbar-a">Artemis Yazılım</a>
            <div className="navbar-right">
                <a className="navbar-a" onClick={Anasayfa}>Anasayfa</a>
                <a className="navbar-a" onClick={IletisimA} >İletişim</a>
            </div>
        </div>
    <section className="homepage-section">
      <Canvas
        className="homepage-canvas"
        camera={{ position: [0, 0, 10], near: 0.1, far: 1000, }}
      >
        <CameraControls Iletisim={Camera} Hook={SetCamera}/>
        <Suspense fallback={null}>
          <directionalLight />
          <ambientLight intensity={0.5} />
          <pointLight />
          <hemisphereLight />
          <Stars/>
          <SpaceShip/>
        </Suspense>
        <Text font={Font}  position={[TextObject.positions.positionx,TextObject.positions.TopPositionY,0]} scale={TextObject.scale}>Hızlı ve güvenli yazılımın adresi</Text>
        <Text font={Font}  position={[TextObject.positions.positionx, TextObject.positions.BottomPositionY,0]} scale={TextObject.scale}>Artemis Yazılım'a Hoşgeldiniz</Text>
        <Text font={Font}  position={[TextObjectIletisim.positioniletisim.iletisimpositionx,TextObjectIletisim.positioniletisim.TopIletisimPositionY,TextObjectIletisim.positioniletisim.positionZ]} rotation={[3.75,-1.1,3.7]} scale={TextObjectIletisim.IletisimScale}>Email : artemisyazilim@gmail.com</Text>
        <Text font={Font}  position={[TextObjectIletisim.positioniletisim.iletisimpositionx,TextObjectIletisim.positioniletisim.BottomIletisimPositionY,TextObjectIletisim.positioniletisim.positionZ]} rotation={[3.75,-1.1,3.7]} scale={TextObjectIletisim.IletisimScale}>Telefon : 05468441948</Text>
      </Canvas>
    </section>
    </div>
  );
};

export default Home;

