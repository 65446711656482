import React from "react";
import Home from "./three";
import Navbar from "./Navbar"
function App() {
  return (
      <Home/>
  )
}

export default App;
