

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import StarsURL from "./extracted_minecraft_java_editions_stars.glb"
const Stars = (props)=> {
  const { nodes, materials } = useGLTF(
    StarsURL
  );
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Object_2.geometry}
        material={materials.star}
        rotation={[-Math.PI / 2, 0, 0]}
      />
    </group>
  );
}

useGLTF.preload("/extracted_minecraft_java_editions_stars.glb");
export default Stars;